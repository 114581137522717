@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');
$color-primary: #2b2b2b;
$color-secondary: #fad02c;
$color-secondary-hover: #d9b525;
$color-background: #f5f5f5;
$color-text: #333333;

/* BODY STYLING */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

body {
	position: relative;
	background-color: #fdfdfd;
}

html {
	scroll-behavior: smooth;
}

/* GALLERY */
#gallery {
	min-height: 100vh;
	text-align: center;
	padding-top: 150px;
	background-color: $color-background;
	.gallery-div {
		padding: 1% 10%;
	}
}
/* ---LOGIN--- */

.login-section {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $color-background;

	.login-form {
		min-width: 400px;
		max-width: 800px;
		margin: 0 auto;
		padding: 40px;
		background-color: #fff;
		margin-top: 50px;
		border-radius: 6px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

		h2 {
			margin-bottom: 30px;
			text-align: center;
			color: $color-primary;
		}

		form {
			.form-group {
				margin-bottom: 20px;

				label {
					display: block;
					margin-bottom: 10px;
					color: $color-primary;
					font-weight: bold;
				}

				input {
					width: 100%;
					padding: 10px;
					border: 1px solid $color-primary;
					border-radius: 4px;
					color: $color-text;
					background-color: $color-background;

					&:focus {
						outline: none;
						border-color: $color-secondary;
					}
				}
			}

			.form-group-row {
				display: flex;
				align-items: center;
				margin-bottom: 20px;

				label {
					display: block;
					margin-bottom: 10px;
					color: $color-primary;
					font-weight: bold;
				}

				input {
					width: 100%;
					padding: 10px;
					border: 1px solid $color-primary;
					border-radius: 4px;
					color: $color-text;
					background-color: $color-background;

					&:focus {
						outline: none;
						border-color: $color-secondary;
					}
				}
			}

			button {
				display: block;
				width: 100%;
				padding: 10px;
				background-color: $color-secondary;
				color: #fff;
				border: none;
				border-radius: 4px;
				cursor: pointer;

				&:hover {
					background-color: darken($color-secondary, 10%);
				}
			}
		}

		hr {
			margin: 30px 0;
			border: none;
			border-top: 1px solid $color-primary;
		}

		p {
			text-align: center;
			color: $color-text;

			a {
				color: $color-secondary;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.notification-page {
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;

	&__title {
		font-size: 24px;
		margin-bottom: 20px;
	}

	&__divider {
		border: none;
		border-top: 1px solid #ddd;
		margin: 40px 0;
	}

	&__input {
		width: 100%;
		padding: 10px;
		font-size: 16px;
		margin-bottom: 20px;
	}

	&__label {
		display: block;
		font-size: 16px;
		margin-bottom: 10px;
	}

	&__submit {
		background-color: #007bff;
		color: #fff;
		padding: 10px 20px;
		border: none;
		font-size: 16px;
		cursor: pointer;
	}

	.notification-list {
		margin-top: 20px;
	}

	.notification {
		border: 1px solid #000;
		padding: 10px;
		margin-bottom: 10px;

		&__content {
			font-size: 16px;
		}
	}
}

/* Autocomplete container styles */
.createOrderContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;

	.autocomplete-container {
		flex: 1;

		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 16px;
		color: #555;
	}

	/* Input element styles */
	.input-element {
		flex: 1;

		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 16px;
		color: #555;
	}

	/* Button element styles */
	.button-element {
		flex: 1;
		margin-top: 10px;
		padding: 10px;
		background-color: $color-secondary;
		color: #fff;
		border: none;
		border-radius: 4px;
		font-size: 16px;
		cursor: pointer;
		transition: background-color 0.3s ease;
	}

	.button-element:hover {
		background-color: $color-secondary-hover;
	}

	/* Placeholder styles */
	::placeholder {
		color: #aaa;
	}

	/* Focus styles */
	.autocomplete-container:focus,
	.input-element:focus,
	.button-element:focus {
		outline: none;
		border-color: $color-secondary;
	}

	/* Mobile responsiveness */
	@media (max-width: 600px) {
		.container {
			flex-direction: column;
			align-items: stretch;
		}

		.autocomplete-container,
		.input-element,
		.button-element {
			flex: 1;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
}

/* NOTIFICATIONS */
$primary-color: #007bff;
$secondary-color: #6c757d;
.notification-page {
	width: 100%;
	.notification-form {
		display: flex;
		flex-direction: column;
		width: 100%;
		.notification-form__input {
			flex: 1;
			margin-top: 10px;
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 16px;
			color: #555;

			&::placeholder {
				color: #999;
			}
		}

		.notification-textarea {
			flex: 1;
			height: calc(
				1.5em * 255 + 20px
			); /* Adjust the line height (1.5em) and padding (20px) based on your design */
			margin-top: 10px;
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 16px;
			color: #555;

			&::placeholder {
				color: #999;
			}
		}

		&__submit {
			flex: 1;
			margin-top: 10px;
			padding: 10px;
			background-color: $color-secondary;
			color: #fff;
			border: none;
			border-radius: 4px;
			font-size: 16px;
			cursor: pointer;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: $color-secondary-hover;
			}
		}

		/* Focus styles */
		.notification-form__input:focus,
		.notification-textarea:focus {
			outline: none;
			border-color: $color-secondary;
		}
	}

	.notification-list {
		.notification {
			border: 1px solid #ddd;
			border-radius: 5px;
			padding: 15px;
			margin-bottom: 15px;
			max-width: auto;

			.date {
				font-size: 12px;
				color: $secondary-color;
				margin-bottom: 5px;
			}

			&__content {
				font-size: 14px;
				color: #555;
				word-wrap: break-word;
			}
		}
	}
}

/* VIP */

.vip-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	.vip-item {
		display: flex;
		flex-direction: column;
		margin-right: 30px;
		.vip-input {
			flex: 1;
			margin-top: 10px;
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 16px;
			color: #555;

			&::placeholder {
				color: #999;
			}
		}
	}

	.vip-input {
		flex: 1;
		margin-top: 10px;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 16px;
		color: #555;

		&::placeholder {
			color: #999;
		}
	}
	.vip-button {
		flex: 1;
		margin-top: 36px;
		padding: 10px;
		background-color: $color-secondary;
		color: #fff;
		border: none;
		border-radius: 4px;
		font-size: 16px;
		cursor: pointer;
		transition: background-color 0.3s ease;

		&:hover {
			background-color: $color-secondary-hover;
		}
	}

	.vip-input:focus {
		outline: none;
		border-color: $color-secondary;
	}
}

.trustpilot {
	width: 20vw;
	margin-bottom: 64px;
}

/* styles.css */
.done {
	background-color: #6bbe45; /* Softer green */
	color: #333; /* Dark text color */
}

.waiting {
	background-color: #ffe933; /* Softer yellow */
	color: #333; /* Dark text color */
}

.accepted {
	background-color: #3383ff; /* Softer blue */
	color: #fff; /* Light text color */
}

.declined {
	background-color: #ff6347; /* Softer red */
	color: #fff; /* Light text color */
}

.pickup {
	background-color: #ff9900; /* Softer red */
	color: #fff; /* Light text color */
}

/* Default style for other statuses */
.default-style {
	background-color: gray;
	color: white;
}

.date-input,
.month-input {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin: 5px;
}

.reset-button,
.display-button {
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin: 5px;
}

.reset-button:hover,
.display-button:hover {
	background-color: #0056b3;
}

.popup-button {
	flex: 1;
	width: 100%;
	margin-top: 10px;
	padding: 10px;
	background-color: $color-secondary;
	color: #fff;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: $color-secondary-hover;
	}
}
